<template>
  <div class="card padded shadow">
    <div class="row">
      <div class="col-auto">
        <h3>Страница вебмастера</h3>
      </div>
      <div class="col text-right" v-if="authorized">
        <a href="#" @click="unauthenticate">Выйти</a>
      </div>
    </div>

    <div class="mt-2" v-if="!authorized">
      <div class="mb-2">
        <p style="color: darkred">Вы не авторизованы.</p>
      </div>
      <div class="alert alert-danger" v-if="wrongCredentials">Неверные данные.</div>
      <b-form-group id="input-group" label="Логин:" label-for="username">
        <b-form-input id="username" v-model="username" type="text" required/>
      </b-form-group>
      <b-form-group id="input-group" label="Пароль:" label-for="password">
        <b-form-input id="password" v-model="password" type="password" required/>
      </b-form-group>
    </div>
    <div class="mt-2" v-if="authorized">
      <div class="alert alert-light">
        Данная страница содержит элементы упрощения создания нового материала для проекта. Заполните необходимые поля, нажмите кнопку "Сгенерировать"
        и вставьте полученный JSON-код в соответствующий файл в Вашем хранилище.
      </div>

      <div class="mb-3">
        <h5>Сведения об истории</h5>
        <div class="alert alert-light">
          <b-form-group id="input-group" label="Заголовок:" label-for="title">
            <b-form-input id="title" v-model="story.title" type="text" required/>
          </b-form-group>
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <multiselect v-model="story.author" :options="authors" track-by="id" label="name" selectLabel="Enter: выбрать" deselectLabel="Enter: снять выбор" placeholder="Выберите автора"></multiselect>
            </div>
            <div class="col-md-6 col-sm-12">
              <multiselect v-model="story.teachers" :options="teachers" track-by="id" label="name" selectLabel="Enter: выбрать" deselectLabel="Enter: исключить" selectedLabel="Выбрано" placeholder="Выберите учителей"
                           :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" :limit="2" :limitText="count => '+' + count"></multiselect>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-2" style="margin-top: 35px">
        <h5>Содержимое истории</h5>
        <div class="alert alert-light">
          <div class="row">
            <div class="col-auto">
              <b-button-group size="sm">
                <b-button :pressed.sync="isWISIWYG" variant="outline-primary" @click="setMode( false )">
                  WISIWYG
                </b-button>
                <b-button :pressed.sync="isHtml" variant="outline-primary" @click="setMode( true )">
                  HTML
                </b-button>
              </b-button-group>
            </div>
            <div class="col">
              <ul style="margin-bottom: 0">
                <li>Режим WISIWYG представляет собой упрощённый визуальный редактор текста. Он автоматически готовит HTML-разметку.</li>
                <li>Режим HTML предоставляет полный контроль над вёрсткой.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <vue-editor v-if="!html" v-model="story.text"/>
      <prism-editor v-if="html" class="my-editor" v-model="story.text" :highlight="highlighter" line-numbers/>

<!--      <div class="ql-editor">-->
<!--        <p v-html="storyText"/>-->
<!--      </div>-->
    </div>

    <div style="margin-top: 35px" class="text-right" v-if="authorized">
      <b-button variant="primary">Сгенерировать</b-button>
    </div>
    <div style="margin-top: 35px" class="text-right" v-if="!authorized">
      <b-button variant="primary" @click="authenticate">Авторизоваться</b-button>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { PrismEditor } from 'vue-prism-editor';
import Prism from 'prismjs';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'vue-prism-editor/dist/prismeditor.min.css';
import 'prismjs/themes/prism.css';
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { mapState } from 'vuex';

export default {
  name: 'WebMaster',
  components: {
    VueEditor,
    PrismEditor,
    Multiselect
  },
  data: () => ({
    wrongCredentials: false,
    username: '',
    password: '',
    html: true,
    story: {
      title: '',
      text: '',
      author: null,
      teachers: []
    }
  }),
  computed: {
    ...mapState({
      authorized: state => state.webmaster.authorized
    }),
    isHtml: {
      get() { return this.html === true; },
      set( value ) { this.html = value }
    },
    isWISIWYG: {
      get() { return this.html === false; },
      set( value ) { this.html = value }
    }
  },
  methods: {
    highlighter(code) {
      return highlight(code, Prism.languages.html, languages.html); // languages.<insert language> to return html with markup
    },
    setMode( mode ) {
      if ( mode === true || this.story.text === '' ) {
        this.html = mode;
      } else {
        let conf = confirm( "Если Ваш HTML содержит переносы строк, редактор автоматически превратит их в '<p></p>'! Вы уверены, что хотите переключить режим?" );
        if ( conf ) {
          this.html = mode;
        }
      }
    },
    authenticate() {
      if ( this.username !== 'admin' || this.password !== 'stories1543' ) {
        this.wrongCredentials = true;
        this.password = '';
      } else {
        this.wrongCredentials = false;
        this.$store.commit( 'authorize' );
      }
    },
    unauthenticate() {
      this.$store.commit( 'deauthorize' );
      this.username = '';
      this.password = '';
    }
  },
  mounted() {
    document.title = 'WebMaster · ADAPT-MS'
  }
}
</script>

<style scoped>
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #ececec;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none !important;
}
</style>
