<template>
  <div class="card padded shadow">
    <div class="row">
      <div class="col">
        <h3>Новости</h3>
      </div>
      <div class="col text-right">
        <b-form-checkbox id="checkbox-1" v-model="showContent" name="checkbox-content" value="show" unchecked-value="hide">
          Показывать текст
        </b-form-checkbox>
      </div>
    </div>
    <div class="alert alert-light" style="padding: 0 10px 0 10px; margin-bottom: 0">
      <i style="color:#065056;">Нажмите на название новости, чтобы её открыть.</i>
    </div>

    <div class="mt-3" style="position:relative; max-height: 680px; overflow-y: auto; padding: 5px">
      <div class="card padded shadow-sm mb-2" v-for="(news, $index) in newsToShow" v-bind:key="$index" :id="$index">
        <div class="row">
          <div class="col">
            <h5 class="pointer-on-hover hover" @click="selectNews( news )">{{ news.title }}</h5>
          </div>
          <div class="col text-right" v-if="authorized">
            <a href="#"><b-icon-pencil/></a>
          </div>
        </div>
        <div style="padding-left: 10px">
          <p>Дата: <i style="color: #a7a7a7">{{ news.date }}</i></p>
          <p v-html="news.content" v-if="showContent === 'show'" style="padding-top: 10px; border-top: 1px solid #ececec"></p>
        </div>
      </div>

      <infinite-loading @infinite="infiniteHandler">
        <div slot="no-more" style="color: #c8c8c8"><i>Больше новостей пока нет...</i></div>
      </infinite-loading>
    </div>

    <b-modal ref="story-modal" scrollable size="xl" hide-footer :title="selectedNews ? selectedNews.title : ''">
      <div v-if="selectedNews" class="d-block" v-html="selectedNews.content" style="padding-bottom: 10px">
      </div>
      <div v-if="selectedNews" style="padding-top: 15px; border-top: 1px solid #c8c8c8; transition: 1s">
        <p>Дата: <i style="color: #a7a7a7">{{ selectedNews.date }}</i></p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import news from '@/data/news.json';
import InfiniteLoading from 'vue-infinite-loading';
import {mapState} from "vuex";

export default {
  name: 'News',
  components: { InfiniteLoading },
  props: ['mode'],
  data: () => ({
    news: news,
    newsToShow: [],
    page: 0,
    showContent: 'hide',
    selectedNews: null
  }),
  computed: {
    ...mapState({
      authorized: state => state.webmaster.authorized
    })
  },
  methods: {
    selectNews( news ) {
      this.selectedNews = news;
      this.$refs['story-modal'].show();
    },
    infiniteHandler( $state ) {
      for ( let i = 15 * this.page; i < 15 * ( this.page + 1 ); i++ ) {
        if ( this.news.length > i ) {
          this.page += 1;
          this.newsToShow.push( this.news[i] );
          $state.loaded();
        } else {
          $state.complete();
          break;
        }
      }
    },
  },
  mounted() {
    document.title = 'Новости · ADAPT-MS'
  }
}
</script>

<style scoped>
.hover:hover {
  color: #065056;
  text-decoration: underline;
}
</style>
