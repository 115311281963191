<template>
  <div class="card padded shadow">
    <div class="row">
      <div class="col">
        <h3>Контакты</h3>
      </div>
    </div>
    <div class="alert alert-info text-center" style="margin-bottom: 0">
      <i style="color:#065056;">Мы всегда готовы к конструктивному диалогу!</i>
    </div>

    <div class="mt-3" style="position:relative; max-height: 680px; overflow-y: auto; padding: 5px">
      <div class="row mb-3">
        <div class="col-lg-3 col-sm-12">
          <b>По вопросам приобретения</b>
        </div>
        <div class="col-lg col-sm-12">
          <a href="mailto:sales@adapt-ms.ru">sales@adapt-ms.ru</a>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-sm-12">
          <b>По вопросам поддержки</b>
        </div>
        <div class="col-lg col-sm-12">
          <a href="mailto:support@adapt-ms.ru">support@adapt-ms.ru</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Contacts',
  data: () => ({
  }),
  methods: {
  },
  mounted() {
    document.title = 'Контакты · ADAPT-MS'
  }
}
</script>

<style scoped>
</style>
