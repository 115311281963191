<template>
  <div class="card padded shadow">
    <h3>Добро пожаловать!</h3>
    <div class="mt-2">
      <div class="alert alert-light">
        <div style="margin-bottom: 15px">ADAPT-MS - активно развивающаяся команда разработки адаптивных систем управления и веб-сайтов различной сложности.</div>
        <div style="margin-bottom: 15px">Мы ставим своей целью разработку для Ваших задач простых в обслуживании информационных систем, нуждающихся в минимальной последующей доработке.</div>
        <div style="margin-bottom: 15px">Решения, которые мы разрабатываем, являются веб-программами, то есть не требуют установки на все компьютеры пользователей. Если на этих компьютерах есть свежие версии актуальных веб-браузеров, наши программные решения будут работать.</div>
        <div style="margin-bottom: 15px">Мы ведём разработку на платформе Java с учетом современных принятых стандартов. В основе нашего программного обеспечения лежат исключительно открытые технологии (Open Source): мы не используем коммерческие библиотеки, поэтому дополнительные расходы для их лицензирования не требуются. Мы следим за тем, чтобы все используемые нами технологии были наиболее новыми и отвечали требованиям безопасности.</div>
        <div>Веб-страницы и интерфейсы веб-приложений могут быть реализованы с использованием реактивных технологий (например, Vue.js).</div>
      </div>
    </div>

    <div style="margin-top: 15px" class="row">
      <div class="col-lg-4 col-sm-12" style="margin-top: 15px">
        <b-card title="Новости" class="shadow-sm">
          <b-card-text>Информация о новых событиях и планах компании</b-card-text>
          <b-button style="margin-top: 15px;" @click="changeRoute( '/News' )" variant="primary">Смотреть</b-button>
        </b-card>
      </div>
      <div class="col-lg-4 col-sm-12" style="margin-top: 15px">
        <b-card title="Проекты" class="shadow-sm">
          <b-card-text>Портфолио - проекты, которые мы создали или поддерживаем</b-card-text>
          <b-button style="margin-top: 15px;" @click="changeRoute( '/Projects' )" variant="outline-primary">Смотреть</b-button>
        </b-card>
      </div>
      <div class="col-lg-4 col-sm-12" style="margin-top: 15px">
        <b-card title="Документация" class="shadow-sm">
          <b-card-text>Перейти на портал документации наших проектов</b-card-text>
          <a href="https://docs.adapt-ms.ru" class="btn btn-outline-primary" style="margin-top: 15px;">Перейти</a>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Main',
  props: ['mode'],
  methods: {
    changeRoute( path ) {
      if ( this.$route.path !== path ) this.$router.push( path );
    },
  },
  mounted() {
    document.title = 'Главная · ADAPT-MS'
  }
}
</script>

<style scoped>

</style>
