const state = {
    authorized: false
}

const mutations = {
    authorize( state ) {
        state.authorized = true;
    },
    deauthorize( state ) {
        state.authorized = false;
    }
}

export default {
    state,
    mutations
}
