import Vue from 'vue'
import VueRouter from "vue-router";
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import store from "@/store";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/global.css';

import Main from "@/components/Main";
import WebMaster from "@/components/WebMaster";
import News from "@/components/News";
import Contacts from "@/components/Contacts";
import Projects from "@/components/Projects";

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

const routes = [
  { path: '/', component: Main },
  { path: '/News', component: News },
  { path: '/Contacts', component: Contacts },
  { path: '/Projects', component: Projects },
  { path: '/WebMaster', component: WebMaster }
]

const router = new VueRouter({
  mode: 'history',
  //base: '/1543/stories/',
  routes,
})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
