import { render, staticRenderFns } from "./Contacts.vue?vue&type=template&id=16274fb8&scoped=true&"
import script from "./Contacts.vue?vue&type=script&lang=js&"
export * from "./Contacts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16274fb8",
  null
  
)

export default component.exports