import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import webmaster from "./modules/webmaster";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        webmaster,
    },
    plugins: [createPersistedState()]
});

export default store
